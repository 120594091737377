<template>
    <div class="panel external-container">
        <h4 class="panel-title">Andamento da Vacinação</h4>

        <div class="internal-container">
            <div class="external-chart-container">
                <div class="chart-container">
                    <canvas ref="myChart"></canvas>

                </div>
            </div>
            <div class="external-container-align-circles">
                <div class="align-legend">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.32031" cy="5" r="5" fill="#2772C052" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ dosesRestantes | addDots }}
                            </div>
                            <div class="format-text-sub">
                                Doses Restantes
                            </div>
                        </div>
                    </div>
                </div>
                <span class="spacing"></span>

                <div class="align-legend">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.32031" cy="5" r="5" fill="#2772C0" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ vaccinationData.dosesAplicadas | addDots }}
                            </div>
                            <div class="format-text-sub">
                                Pessoas
                            </div>
                            <div class="format-text-sub">
                                Vacinadas
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="group-total-doses-external">
            <div class="group-total-doses">
                <div>
                    <img class="img-total-doses" src="@/assets/custom-icons/pages/dashboard/syringe.svg" Alt="Syringe">
                </div>
                <div class="format-total-doses-text-1">
                    Total de doses solicitadas:
                    <span class="format-total-doses-text-2">{{ vaccinationData.dosesSolicitadas | addDots }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import Chart from 'chart.js';
Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.labels.usePointStyle = true;

export default {
    mixins: [ dashUtilsMixin ],

    props: {
        vaccinationData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            myChart: null,
        };
    },
    watch: {
        vaccinationData() {
            this.renderChart();
        }
    },
    computed: {
        dosesRestantes() {
            return this.vaccinationData.dosesSolicitadas - this.vaccinationData.dosesAplicadas
        }
    },

    mounted() {
        this.renderChart();
    },

    methods: {
        renderChart() {
            if (this.myChart) this.myChart.destroy();
            const ctx = this.$refs.myChart.getContext('2d');
            const config = this.getChartConfig();
            this.myChart = new Chart(ctx, config);
            const vaccinationData = this.vaccinationData;
            Chart.pluginService.register({
                beforeDraw: function (chart) {
                    if (chart.config.type === 'doughnut') {
                        var width = chart.chart.width,
                            height = chart.chart.height,
                            ctx = chart.chart.ctx;

                        ctx.clearRect(0, 0, width, height); // Limpa o canvas

                        var fontSize = (height / 114).toFixed(2);
                        ctx.font = "bold " + fontSize + "em montserrat";
                        ctx.bodyFontColor = "red"
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "#5E5873";

                        var text = `${((vaccinationData.dosesAplicadas / vaccinationData.dosesSolicitadas) * 100).toFixed(0).toString()}%`,
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = height / 2.2;
                        ctx.fillText(text, textX, textY);
                        ctx.save();

                        var width = chart.chart.width,
                            height = chart.chart.height,
                            ctx = chart.chart.ctx;

                        var fontSize = (height / 214).toFixed(2);
                        ctx.font = fontSize + "em montserrat";
                        ctx.bodyFontColor = "red"
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "#5E5873";

                        var text2 = "Completo",
                            textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
                            textY2 = height / 1.7;

                        ctx.fillText(text2, textX2, textY2);
                        ctx.save();
                    }
                },

            });
        },
        getChartConfig() {
            const labels = ['Doses restantes', 'Pessoas vacinadas'];
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Doses solicitadas',
                        data: [this.dosesRestantes, this.vaccinationData.dosesAplicadas],
                        backgroundColor: ['#2772C052', '#2772C0']
                    },
                ],
            };

            const config = {
                type: 'doughnut',
                data: data,
                options: {
                    cutoutPercentage: 60,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins:
                    {

                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                            }
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Line Chart',
                        },
                    },

                    tooltips: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        backgroundColor: '#FFF',
                        titleFontColor: '#5E5873',
                        bodyFontColor: '#5E5873',
                        borderColor: "#2772C0",
                        borderWidth: 1,
                        titleFontFamily: "Montserrat",
                        titleFontSize: 12,
                        bodyFontFamily: "Montserrat",
                        bodyFontSize: 12,
                        cornerRadius: 6,
                    },


                },
                plugin: [{
                    id: 'my-doughnut-text-plugin',
                    afterDraw: function (chart, option) {
                        let theCenterText = "90%";
                        const canvasBounds = canvas.getBoundingClientRect();
                        const fontSz = Math.floor(canvasBounds.height * 0.10);
                        chart.ctx.textBaseline = 'middle';
                        chart.ctx.textAlign = 'center';
                        chart.ctx.font = fontSz + 'px Arial';
                        chart.ctx.fillText(theCenterText, canvasBounds.width / 2, canvasBounds.height * 0.70)
                    }
                }]
            };
            return config;
        },
    },
};

</script>
<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* CSS RESET */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.format-total-doses-text-1 {
    font-family: Montserrat;
    font-size: responsive-width(14px);
    font-weight: 600;
    line-height: responsive-width(17px);
    letter-spacing: 0px;
    text-align: center;
    color: #2772C0CC;
    padding-left: responsive-width(9px);
}

.format-total-doses-text-2 {

    font-family: Montserrat;
    font-size: responsive-width(14px);
    font-weight: 500;
    line-height: responsive-width(17px);
    letter-spacing: 0px;
    text-align: center;
    color: #2772C0CC;
}


.group-total-doses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    background-color: #2772C00F;
    width: responsive-width(270px);
    height: responsive-height(28px);
    border-radius: 20px;
}

.group-total-doses-external {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding-top: responsive-height(25px);

}

.img-total-doses {
    width: responsive-width(18px);
    height: responsive-width(18px)
}



.align-circle-div-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
    text-align: center;
}


.align-circle-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
}

.circle-format {
    padding-right: responsive-width(6px);
}

.format-text {
    color: var(--1-theme-color-dark, #4B4B4B);
    text-align: start;
    font-family: Montserrat;
    font-size: responsive-height(24px);
    font-style: normal;
    font-weight: 500;
    line-height: responsive-height(29px);
}

.format-text-sub {
    color: var(--1-theme-color-dark, #6E6B7B);
    text-align: start;
    font-family: Montserrat;
    font-size: responsive-height(14px);
    font-style: normal;
    font-weight: 400;
    line-height: responsive-height(21px);
}

.align-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
}

.spacing {
    padding-bottom: responsive-height(33px);
}

.external-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.internal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-top: responsive-height(38px);
}

.external-container-align-circles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    padding-left: responsive-width(31px);

}

.external-chart-container {
    display: flex;
    justify-content: center;
    justify-items: flex-start;
    align-content: center;
    align-items: center;
}

.chart-container {
    width: responsive-width(211px);
    height: responsive-height(211px);

}

// MOBILE
@media only screen and (max-width: 780px) {
    .internal-container {
        margin-top: 10px;
    }
}
</style>