<template>
    <div class="panel">
        <slot></slot>
        <div class="internal-container">
            <div class="external-chart-container">
                <div class="chart-container">
                    <canvas ref="myChart"></canvas>
                </div>
            </div>
            <div class="external-container-align-circles">

                <div v-for="(item, index) in this.panelData" :key="index" class="align-legend">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle :cx="5.32031" cy="5" r="5" :fill="donutColors[index]" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ animatedNumbers[index].toFixed(0) | addDots }}
                            </div>
                            <div class="format-text-sub">
                                {{ item.nome }}
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
import gsap from 'gsap';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.labels.usePointStyle = true;

export default {
    mixins: [dashUtilsMixin],

    props: {
        panelData: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            myChart: null,
            donutLabels: [],
            donutAmount: [],
            donutColors: [],
            animatedNumbers: this.panelData.map(item => 0)
        };
    },

    watch: {
        panelData() {
            this.renderChart();
        }
    },

    created() {
        this.fields = [{ nome: false, }, { cnpj: false }, { colaboradores_vacinados: false },
        { doses_restantes: false }
        ];
    },

    mounted() {
        for (let i = 0; i < this.panelData.length; i++) {
            this.animateNumber(i, this.panelData[i].quantidade)
        }
        this.renderChart();
    },

    methods: {
        animateNumber(index, newValue) {
            gsap.to(this.animatedNumbers, {
                duration: 1,
                [index]: newValue,
                onUpdate: () => {
                    this.$set(this.animatedNumbers, index, this.animatedNumbers[index])
                }
            })
        },

        renderChart() {
            // Iterando sobre os itens de this.panelData
            this.donutLabels = [];
            this.donutAmount = [];
            this.donutColors = [];
            this.panelData.forEach(item => {
                // Adicionando o nome do item à lista donutLabels
                this.donutLabels.push(item.nome);

                // Adicionando a quantidade do item à lista donutAmount
                this.donutAmount.push(item.quantidade);
            });
            this.donutColors = ['#2772C052', '#2772C0', '#28C76F']

            if (this.myChart) this.myChart.destroy();
            const ctx = this.$refs.myChart.getContext('2d');
            const config = this.getChartConfig();
            this.myChart = new Chart(ctx, config);
            Chart.pluginService.register({
                beforeDraw: (chart) => {
                    if (chart.config.type === 'doughnut') {
                        var width = chart.chart.width,
                            height = chart.chart.height,
                            ctx = chart.chart.ctx;

                        ctx.clearRect(0, 0, width, height); // Limpa o canvas

                        var fontSize = (height / 114).toFixed(2);
                        ctx.font = "500 " + fontSize + "em montserrat";
                        ctx.bodyFontColor = "red"
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "#5E5873";

                        var number = this.donutAmount.reduce((acc, curr) => acc + curr, 0);
                        var formattedNumber = this.$options.filters.addDots(number),
                            text = formattedNumber,
                            textX = Math.round((width - ctx.measureText(text).width) / 2),
                            textY = height / 2.1;
                        ctx.fillText(text, textX, textY);
                        ctx.save();

                        var fontSize = (height / 195).toFixed(2);
                        ctx.font = "400 " + fontSize + "em montserrat";
                        ctx.bodyFontColor = "red"
                        ctx.textBaseline = "middle";
                        ctx.fillStyle = "#5E5873";

                        var text2 = "Doses",
                            textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
                            textY2 = height / 1.65;

                        ctx.fillText(text2, textX2, textY2);
                        ctx.save();
                    }
                },

            });
        },
        updateColors(column, ascending) {
            for (const key in this.colors) {
                if (key === this.orderByColumn) {
                    this.colors[key].asc = this.isAscending ? '#6E6B7B' : '#BABFC7'; // Altera a cor da seta ascendente
                    this.colors[key].desc = this.isAscending ? '#BABFC7' : '#6E6B7B'; // Altera a cor da seta descendente
                } else {
                    this.colors[key].asc = '#BABFC7'; // Cor padrão para outras colunas
                    this.colors[key].desc = '#BABFC7'; // Cor padrão para outras colunas
                }
            }
        },
        getChartConfig() {
            const labels = this.donutLabels;
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Doses solicitadas',
                        data: this.donutAmount,
                        backgroundColor: this.donutColors
                    },
                ],
            };

            const config = {
                type: 'doughnut',
                data: data,
                options: {
                    cutoutPercentage: 65,
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins:
                    {

                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                            }
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Line Chart',
                        },
                    },

                    tooltips: {
                        enabled: false
                    },


                },
                plugin: [{
                    id: 'my-doughnut-text-plugin',
                    afterDraw: function (chart, option) {
                        let theCenterText = "90%";
                        const canvasBounds = canvas.getBoundingClientRect();
                        const fontSz = Math.floor(canvasBounds.height * 0.10);
                        chart.ctx.textBaseline = 'middle';
                        chart.ctx.textAlign = 'center';
                        chart.ctx.font = fontSz + 'px Arial';
                        chart.ctx.fillText(theCenterText, canvasBounds.width / 2, canvasBounds.height * 0.70)
                    }
                }]
            };
            return config;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";


.img-total-doses {
    width: responsive-width(18px);
    height: responsive-width(18px)
}



.align-circle-div-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
    text-align: center;
}


.align-circle-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    justify-items: flex-start;
}

.circle-format {
    padding-right: responsive-width(6px);
}

.format-text {
    color: var(--1-theme-color-dark, #5E5873);
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(24px);
    font-style: normal;
    font-weight: 500;
    line-height: responsive-height(29px);
}

.format-text-sub {
    color: var(--1-theme-color-dark, #6E6B7B);
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-style: normal;
    font-weight: 400;
    line-height: responsive-height(21px);
}

.align-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
}

.spacing {
    padding-bottom: responsive-height(33px);
}

.external-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.internal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-top: responsive-height(77px);
}

.external-container-align-circles {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: center;
    align-content: center;
    padding-left: responsive-width(28px);
}

.external-chart-container {
    display: flex;
    justify-content: center;
    justify-items: flex-start;
    align-content: center;
    align-items: center;
}

.chart-container {
    width: responsive-width(174px);
    height: responsive-height(174px);

}

// MOBILE
@media only screen and (max-width: 780px) {
    .internal-container {
        margin-top: 10px;
    }
}
</style>