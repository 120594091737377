<template>
    <div class="no-data-found">
        <img :src="warnImg" alt="icone de alerta">
        <h2>Não há dados para serem exibidos</h2>
        <p>Atualize os filtros para mostrar dados.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            warnImg: require(`@/assets/custom-icons/pages/dashboard/warn-icon.png`),
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.no-data-found {
    text-align: center;

    img {
        width: 80px;
        height: 80px;
        margin-top: 3px;
        margin-bottom: responsive-height(25px);
    }
    
    h2 {
        font-weight: 600;
        font-size: responsive-height(24px);
        line-height: responsive-height(30px);
        margin-bottom: 3px;
    }
    
    p {
        font-weight: 400;
        font-size: responsive-height(14px);
        line-height: responsive-height(20px);
    }
}
</style>