<template>
    <div class="panel">

        <h4 class="panel-title">Vacinação por Empresa</h4>

        <table class="table-container">
            <thead>
                <tr>
                    <th class="th">
                        <div class="align-external-imgs-donw-up-1">
                            <span class="span-tr-1">NOME FANTASIA

                            </span>
                            <div class="align-imgs-down-up">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('name', 'ASC', 0, 1)" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291244)">
                                        <rect x="0.915894" width="14.0841" height="14" :fill="colors.name.asc" />
                                    </g>
                                </svg>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('name', 'DESC', 0, 1)" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291243)">
                                        <rect x="0.915894" width="14.0841" height="14" :fill="colors.name.desc" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </th>

                    <th class="th-2">
                        <div class="align-external-imgs-donw-up-2">
                            <span class="span-tr-2">CNPJ</span>
                            <div class="align-imgs-down-up">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('cnpj', 'ASC', 0, 1)" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291244)">
                                        <rect x="0.915894" width="14.0841" height="14"  :fill="colors.cnpj.asc"/>
                                    </g>
                                </svg>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('cnpj', 'DESC', 0, 1)" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291243)">
                                        <rect x="0.915894" width="14.0841" height="14" :fill="colors.cnpj.desc" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </th>
                    <th class="th-2">

                        <div class="align-external-imgs-donw-up-3">
                            <span class="span-tr-3">COLABORADORES VACINADOS</span>
                            <div class="align-imgs-down-up">

                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('vaccinated_employees', 'DESC', 0, 1)"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291244)">
                                        <rect x="0.915894" width="14.0841" height="14" :fill="colors.vaccinated_employees.desc"/>
                                    </g>
                                </svg>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('vaccinated_employees', 'ASC', 0)"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291243)">
                                        <rect x="0.915894" width="14.0841" height="14" :fill="colors.vaccinated_employees.asc" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </th>
                    <th class="th-2">
                        <div class="align-external-imgs-donw-up-4">
                            <span class="span-tr-4">DOSES RESTANTES </span>
                            <div class="align-imgs-down-up">
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('remaining_doses', 'DESC', 0, 1)"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291244" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 9.15964C11.7724 9.2763 11.655 9.33464 11.479 9.33464C11.3029 9.33464 11.1856 9.2763 11.0682 9.15964L7.95795 6.06797L4.84772 9.15964C4.61299 9.39297 4.26088 9.39297 4.02615 9.15964C3.79141 8.9263 3.79141 8.5763 4.02615 8.34297L7.54717 4.84297C7.7819 4.60964 8.134 4.60964 8.36874 4.84297L11.8898 8.34297C12.1245 8.5763 12.1245 8.9263 11.8898 9.15964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291244)">
                                        <rect x="0.915894" width="14.0841" height="14"
                                            :fill="colors.remaining_doses.desc" />
                                    </g>
                                </svg>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                    @click="onClickButton('remaining_doses', 'ASC', 0, 1)"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                        fill="black" />
                                    <mask id="mask0_16963_291243" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                        x="3" y="4" width="10" height="6">
                                        <path
                                            d="M11.8898 5.65964L8.36874 9.15964C8.25137 9.2763 8.134 9.33464 7.95795 9.33464C7.7819 9.33464 7.66454 9.2763 7.54717 9.15964L4.02615 5.65964C3.79141 5.4263 3.79141 5.0763 4.02615 4.84297C4.26088 4.60964 4.61299 4.60964 4.84772 4.84297L7.95795 7.93464L11.0682 4.84297C11.3029 4.60964 11.655 4.60964 11.8898 4.84297C12.1245 5.0763 12.1245 5.4263 11.8898 5.65964Z"
                                            fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_16963_291243)">
                                        <rect x="0.915894" width="14.0841" height="14"
                                        :fill="colors.remaining_doses.asc" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <div class="container-table-scroll">
                    <tr v-for="(vaccinationByCompany, index) in tableData.companies" :key="index">
                        <td class="max-td">{{ vaccinationByCompany.name }}
                        </td>
                        <td class="cnpj">{{ vaccinationByCompany.cnpj }}</td>
                        <td class="colaboradores-vacinados">{{ vaccinationByCompany.vaccinated_employees }}</td>
                        <td class="doses-restantes">{{ vaccinationByCompany.remaining_doses }}</td>

                    </tr>
                </div>
                <div class="container-table-shadow "></div>
                <div class="container-table-bottom">
                    <div class="container-table-bottom-title-pagination">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage" />

                    </div>
                </div>
            </tbody>
        </table>

    </div>
</template>

<script>
import { getVaccinatorTableData } from '@/views/custom-pages/dashboard/requests';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';

export default {


    components: {
        CustomPagination
    },
    props: {
        panelData: {
            type: Object,
            required: true
        },
        currentFilteredParams: {
            type: Object,
            required: true
        }
    },
    watch: {
        panelData() {
            this.tableData = this.panelData
        }
    },
    data() {
        return {
            tableData: this.panelData,
            orderBy: null,
            orderType: null,
            page: 0,
            paginationData: {
                currentPage: 1,
                totalLines: this.panelData.totalRows,
                fromLine: 0,
                toLine: 20,
                defaultSize: 0,
            },
            colors: {
                name: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                cnpj: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                vaccinated_employees: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
                remaining_doses: {
                    asc: '#BABFC7',
                    desc: '#BABFC7'
                },
            }
        }
    },
    methods: {
        async onClickButton(orderBy, orderType, page, currentPage) {
            this.updateColors(orderBy, orderType);
            this.orderBy = orderBy;
            this.orderType = orderType;
            if (currentPage) {
                this.paginationData.currentPage = currentPage;
            }
            const params = {

                startDate: this.currentFilteredParams.startDate,
                endDate: this.currentFilteredParams.endDate,
                campaigns: this.currentFilteredParams.campaigns,
                uo: this.currentFilteredParams.uo,
                orderBy: this.orderBy,
                orderType: this.orderType,
                page: page,
            };
            const response = await getVaccinatorTableData(params);
            this.tableData = response.data;
        },

        updateColors(orderBy, orderType) {
            for (const key in this.colors) {

                if (key === orderBy && orderType === 'ASC') {
                    this.colors[key].asc = '#6E6B7B';
                    this.colors[key].desc = '#BABFC7';

                }

                if (key === orderBy && orderType === 'DESC') {
                    this.colors[key].asc = '#BABFC7';
                    this.colors[key].desc = '#6E6B7B';

                }

                if (key != orderBy ){
                    this.colors[key].asc = '#BABFC7';
                    this.colors[key].desc = '#BABFC7';
                }
            }
        },

        updateCurrentPage(page) {
            this.paginationData.fromLine = (page - 1) * 20;
            this.paginationData.toLine = page * 20;
            page = page - 1;
            this.onClickButton(this.orderBy, this.orderType, page)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";


.justify-content-between {
    align-items: center;
}

.max-td {
    min-width: 40%;
    // padding-right: responsive-width(30px);
}

.container-table-scroll {
    height: responsive-height(500px);
    overflow: scroll;
}

.container-table-bottom {
    height: responsive-height(67px);
    box-shadow: 0px 0px 10rem rgba(0, 0, 0, 0.205);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    justify-items: center;

}

.container-table-bottom-title-pagination {
    height: responsive-height(28px);
    width: 100%;
    margin-right: responsive-width(10px);
    margin-left: responsive-width(10px);
}

.container-table-bottom-pagination-title {
    height: responsive-height(67px);
    box-shadow: 0px 0px 10rem rgba(0, 0, 0, 0.205);

}

td {
    display: flex;

    width: 100%;
}

tr {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    width: 100%;

}

.th {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    min-width: 40%;
    height: responsive-height(50px);
}

.th-2 {
    display: flex;
    justify-items: center;
    align-items: center;
    align-content: center;
    height: responsive-height(50px);

}

.span-tr-1 {
    width: 100%;

    // white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    justify-items: center;
    align-content: center;

}

.span-tr-2 {
    width: 100%;

    // white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    // justify-items: center;
    // align-content: center;
    margin-left: responsive-width(40px);


}

.span-tr-3 {
    width: 100%;

    // white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    // justify-items: center;
    // align-content: center;
    margin-left: responsive-width(40px);

}

.span-tr-4 {
    width: 100%;

    // white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    // justify-items: center;
    // align-content: center;
    margin-left: responsive-width(40px);

}


.align-external-imgs-donw-up-1 {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: end;
    justify-items: center;
    align-items: center;
    align-content: center;

    // padding-left: responsive-width(264px);
    // padding-right: responsive-width(88px);
    // padding-right: responsive-width(88px);

}

.align-external-imgs-donw-up-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    // align-content: center;
    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);

    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);

}

.align-external-imgs-donw-up-3 {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    // align-content: center;
    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);

    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);
}

.align-external-imgs-donw-up-4 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    // align-content: center;
    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);

    // padding-left: responsive-width(88px);
    // padding-right: responsive-width(88px);
}


.img-asc {
    cursor: pointer;
}


.img-desc {
    cursor: pointer;
}

.padding-up {
    height: responsive-height(77px);
    display: flex;
    align-items: center;
}

.align-imgs-down-up {
    display: flex;
    flex-direction: column;
}

.table-container tr {
    font-size: mobile-responsive-height(12px) !important;
    // padding-top: responsive-height(20px);

}

.table-container {
    width: 100%;
}

.table-container svg {
    cursor: pointer;
}

.table-container tr th {
    font-family: Montserrat;
    font-size: responsive-height(12px);
    font-weight: 600;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    height: responsive-height(40px);
    color: #5E5873;
    padding-left: responsive-width(16px);
    background-color: #F3F2F7 !important;
    width: 100%;

}

.table-container .cnpj {
    font-family: Montserrat;
    font-size: get-vh(12px);
    font-weight: 400;
    line-height: get-vh(15px);
    letter-spacing: 0px;
    text-align: left;
    margin-left: responsive-width(40px);

}

.table-container .colaboradores-vacinados {
    font-family: Montserrat;
    font-size: get-vh(14px);
    font-weight: 400;
    line-height: get-vh(21px);
    letter-spacing: 0px;
    text-align: left;
    margin-left: responsive-width(40px);


}

.table-container .doses-restantes {
    font-family: Montserrat;
    font-size: get-vh(14px);
    font-weight: 400;
    line-height: get-vh(21px);
    letter-spacing: 0px;
    text-align: left;
    margin-left: responsive-width(40px);


}

.table-container td {
    padding-left: responsive-width(16px);
    padding-top: responsive-height(14px);
    padding-bottom: responsive-height(14px);
    font-family: Montserrat;
    font-size: responsive-height(14px);
    font-weight: 600;
    line-height: responsive-height(21px);
    letter-spacing: 0px;
    text-align: left;


}

.table-container td div {
    font-family: Montserrat;
    font-size: responsive-height(12px);
    font-weight: 400;
    line-height: responsive-height(15px);
    letter-spacing: 0px;
    text-align: left;
    color: #6E6B7B;
}

.table-container tr {
    border-top: 1px solid #F3F2F7;
    border-bottom: 1px solid #F3F2F7;

}

.dashboard-comparative-data {
    padding: 0;
    min-height: 450px;
}

.start-icon {
    width: responsive-width(14px);
    height: responsive-width(14px);
    margin-right: responsive-width(8px);
}

.panel {
    padding: responsive-height(20px) 0 0 0;
    width: 100%;
    min-height: 0 !important;

}

.panel-title {
    padding: 0 responsive-height(20px) responsive-height(20px) responsive-height(20px)
}
</style>