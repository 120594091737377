<template>
  <div>
    <div
      v-for="aviso in avisos"
      :key="aviso.id_notificacao"
    >
      <ModalShowAviso
        v-if="aviso.show"
        :aviso="aviso"
        :show-modal="aviso.show"
        @confirmed="viewAviso(aviso)"
      />
    </div>
    
    <section v-if="perfilSuporte" class="d-flex justify-content-center mt-5">
      <div class="box-img-home mt-2">
        <img :src="imgLogo" alt="Bem-vindo ao VacinaSESI">
      </div>
    </section>

    <section v-else>
      <DashboardView />
    </section>

  </div>
</template>

<script>
import moment from 'moment'
import { actions, subjects } from '@/libs/acl/rules'
import { isUserLoggedIn } from '@/auth/utils'
import ModalShowAviso from '@/views/components/custom/modals/ModalShowAviso.vue'
import { TIPONOTIFICACAO } from '@/enum/tipo-notificacao'
import DashboardView from '@/views/custom-pages/dashboard/Index.vue'
import { getUserCompanyList } from "@core/utils/store/getStore";
import { setUserCompanyList } from "@core/utils/store/setStore";

export default {
  title: 'Bem vindo!',

  components: {
    ModalShowAviso,
    DashboardView
  },
  
  data() {
    return {
      moment,
      imgLogo: require('@/assets/images/pages/bem-vindo-vacina-sesi.png'),
      avisos: [],
      modal: {
        showModal: true,
      },
      show: true,
      perfilSuporte: this.$can(actions.VISUALIZAR, subjects.SUPORTE_GERENCIAMENTO_FILA),
      userData: this.$store.state.sessions.userData,
      company: null,
    }
  },

  mounted() {
    const profiles = this.userData.perfis.map(profile => profile.id_perfil)
    if (!getUserCompanyList().length && this.userData.empresas.length === 0 && profiles.includes(this.$perfil.ADMIN_EMPRESA)) {

      this.$router.push({
        name: 'empresa-register',
      })

      return
    }

    if(!getUserCompanyList().length) {
      setUserCompanyList(this.userData.empresas)
      this.company = getUserCompanyList()[0]
    }else{
      this.company = getUserCompanyList()[0]
    }

    if (this.$route.params.idAjuda) {
      this.$router.push(
        {
          name: 'pages-faq',
          params: {
            idAjuda: this.$route.params.idAjuda,
            categoria: this.$route.params.categoria,
          },
        },
      )
    }

    if (this.$route.params.redirectToUrl) {
      this.$router.push(
        {
          name: this.$route.params.routeName,
          params: this.$route.params.routeParams,
        },
      )
    }

    if (isUserLoggedIn()) {
      this.loadDataAvisoNaoVisualizado()
    }
  },
  methods: {
    loadDataAvisoNaoVisualizado() {
      this.$http.get(this.$api.getNotificacoesVigentes(), {
        params: {
          visualizado: false,
          tipo: TIPONOTIFICACAO.AVISO,
          vigencia: moment().format('YYYY-MM-DD'),
        },
      })
        .then(({ data }) => {
          this.avisos = this.filterList(data.data)
        })
    },
    viewAviso(aviso) {
      this.$http.patch(this.$api.viewNotificacaoNaoVisualizada(aviso.id_notificacao_usuario)).then(() => {
        this.loadDataAvisoNaoVisualizado()
      })
    },
    filterList(list) {
      list.filter((item, index) => {
        item.show = index === 0 && !item.notificado
      })
      return list
    },
  },
}
</script>

<style scoped>
.box-img-home {
  max-width: 500px;
  position: relative;
}

.box-img-home img {
  width: 100%;
  opacity: .3;
}
</style>
