<template>
  <b-modal
        id="show-aviso"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
        hide-footer
        centered
        v-if="aviso"
        v-model="showModal"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2 text-center">
            
            <h2 class="text-center">{{ aviso.notificacao.titulo }}</h2>
            <span>{{ aviso.notificacao.conteudo }}</span>

            <b-link v-if="aviso.notificacao.id_ajuda" @click.prevent="redirectToAjuda" class="text-custom-blue d-flex align-items-center justify-center-center mt-2 font-weight-bold"> 
            <feather-icon
                icon="HelpCircleIcon"
                class="text-custom-blue"
                style="margin-right: 5px"
                size="18"
            />
            {{ aviso.notificacao.ajuda.topico }}
            </b-link> 

            <div class="d-flex mt-1">
                <b-button variant="primary-button" class="mr-2" @click="confirmAction">
                    Entendi!
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton, BLink } from 'bootstrap-vue';

export default {
  components: {
        BModal, BButton, BLink
    },
  props: {
    aviso: {
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    }
  },
  created() {

    window.addEventListener('keydown',(event)=>{
      if (event.code === 'Enter' && this.aviso) {
          this.aviso.show = false;
          this.$emit('confirmed');
      }
    });
  },
  methods: {
    confirmAction(modalEvent) {
            this.aviso.show = false;
            modalEvent.preventDefault();
            this.$emit('confirmed', modalEvent);
    },

    redirectToAjuda() {
            this.$router.push(
                { 
                    name: 'pages-faq', 
                    params: {
                        idAjuda: this.aviso.notificacao.ajuda.id_ajuda,
                        categoria: this.aviso.notificacao.ajuda.categorias[0].id_categoria
                    }
                }
            ).catch(() => {
              this.$router.push(
                { 
                    name: 'home',
                    params: {
                        idAjuda: this.aviso.notificacao.ajuda.id_ajuda,
                        categoria: this.aviso.notificacao.ajuda.categorias[0].id_categoria
                    }
                });
            });
          this.$emit('confirmed');
        }
  }

}
</script>

<style scoped>
    .bloco{
      height: 100px;
    }
    .avisos{
          background: linear-gradient(60deg, #115EAD 0%, #2772c0 100%);
          width: 100%;
          box-sizing: border-box;
    }
    
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }
</style>
