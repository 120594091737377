<template>
    <div class="before-filtrage">
        <img :src="searchImg" alt="icone de busca">
        <h2>Filtre para exibir dados</h2>
        <p>Para exibir dados no painel, utilize os filtros acima.</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchImg: require(`@/assets/custom-icons/pages/dashboard/search.png`),
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.before-filtrage {
    text-align: center;

    img {
        margin-top: 3px;
        margin-bottom: responsive-height(25px);
    }
    
    h2 {
        font-weight: 600;
        font-size: responsive-height(24px);
        line-height: responsive-height(30px);
        margin-bottom: 3px;
    }
    
    p {
        font-weight: 400;
        font-size: responsive-height(14px);
        line-height: responsive-height(20px);
    }
}
</style>