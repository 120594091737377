<template>
    <div class="chart-master panel">
        <h4 class="panel-title">Alcance da Vacinação</h4>

        <div class="percentage-chart">
            <svg version="1.1" x="0px" y="0px" viewBox="65.5 115 198 157" enable-background="new 65.5 115 198 157">
                <path ref="chartTotalLine" :style="totalLineLength" stroke-miterlimit="10" d="M90.826,266.738
                    c-11.8-15.488-19.972-33.437-19.972-54.301c0-51.186,42.078-92.679,93.984-92.679c51.905,0,93.983,41.493,93.983,92.679
                    c0,20.826-8.211,39.087-19.97,54.563" />
                <path :style="vaccinatedLineLength" stroke-miterlimit="10" d="M90.826,266.738
                    c-11.8-15.488-19.972-33.437-19.972-54.301c0-51.186,42.078-92.679,93.984-92.679c51.905,0,93.983,41.493,93.983,92.679
                    c0,20.826-8.211,39.087-19.97,54.563" />
            </svg>

            <div class="percentage-display">
                <p class="percentage-number">{{ percentageVaccinated }}%</p>
                <p class="percentage-text">Colaboradores <br>Vacinados</p>
            </div>
        </div>

        <div class="chart-subtitle">
            <div class="subtitle" :title="`${data.vaccinatedColaborators} colaboradores vacinados`">
                <div class="align-content-data-vaccinated">
                    <div class="mini-circle green"></div>
                    <div class="label-colaborators">
                        {{ data.vaccinatedColaborators }}
                    </div>
                </div>
                <p>Colaboradores <br>vacinados</p>
            </div>
            <div class="division-line"></div>
            <div class="subtitle" :title="`${data.totalColaborators} colaboradores cadastrados`">
                <div class="align-content-data-vaccinated">

                    <div class="mini-circle lightgreen"></div>
                    <div class="label-colaborators">
                        {{ data.totalColaborators }}
                    </div>
                </div>
                <p>Colaboradores <br>cadastrados</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            totalLineLength: {},
            vaccinatedLineLength: {}
        }
    },

    computed: {
        percentageVaccinated() {
            if (this.data.totalColaborators == 0) return 0
            return Math.round(this.data.vaccinatedColaborators / this.data.totalColaborators * 100)
        },
    },

    watch: {
        percentageVaccinated() {
            this.updateChart()
        }
    },

    mounted() {
        this.updateChart()
    },

    methods: {
        updateChart() {
            const length = this.$refs.chartTotalLine.getTotalLength()
            const fillingStroke = length - (length * this.percentageVaccinated / 100)

            this.totalLineLength = {
                "stroke-dasharray": `${length}`
            }

            this.vaccinatedLineLength = {
                "stroke-dasharray": `${length}`,
                "stroke-dashoffset": `${fillingStroke}`,
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.align-content-data-vaccinated {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-colaborators{
    font-weight: 600;
}

.chart-master {
    * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
    }
}

.percentage-chart {
    position: relative;
    width: responsive-height(240px, 180px);
    height: responsive-height(240px, 180px);
    margin: 0 auto;

    svg {
        width: responsive-height(240px, 180px);
        height: responsive-height(240px, 180px);

        path {
            fill: none;
            stroke-width: 2px;
            stroke: #90E09E;
            stroke-linejoin: round;
        }

        path:nth-child(1) {
            stroke-dashoffset: 0;
        }

        path:nth-child(2) {
            stroke-width: responsive-height(8px);
            stroke: #24B263;
            transition: 1.2s ease;
        }
    }

    .percentage-display {
        position: absolute;
        top: responsive-height(10px);
        width: responsive-height(240px, 180px);
        height: responsive-height(240px, 180px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .percentage-number {
            font-weight: 500;
            font-size: responsive-height(36px);
            line-height: responsive-height(44px);
            color: #5E5873;
        }

        .percentage-text {
            font-weight: 400;
            font-size: responsive-height(14px);
            line-height: responsive-height(18px);
            color: #6E6B7B;
            text-align: center;
        }
    }
}

.chart-subtitle {
    display: flex;
    justify-content: center;

    .subtitle {
        text-align: center;
        font-size: responsive-height(14px);
        line-height: responsive-height(18px);
        z-index: 1;
    }

    .division-line {
        width: 1px;
        background-color: #EBE9F1;
        margin: 0 responsive-width(55px);
    }

    .mini-circle {
        width: responsive-height(10px);
        height: responsive-height(10px);
        border-radius: 50%;
        margin-right: responsive-width(6px);

        &.lightgreen {
            background-color: #90E09E;
        }

        &.green {
            background-color: #24B263;
        }
    }


}
</style>