<template>
    <div class="no-campaign">
        <img :src="warnImg" alt="Ícone Erro" class="error-icon">
        <h1>Adesão não encontrada</h1>
        <h6>Não existe adesão a campanhas.</h6>
    </div>
</template>

<script>
export default {
    data() {
        return {
            warnImg: require('@/assets/custom-icons/pages/dashboard/error-icon.png')
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.no-campaign {
	text-align: center;
	margin-top: responsive-height(140px);

	.error-icon {
		width: responsive-height(100px);
		height: responsive-height(100px);
		margin-bottom: responsive-height(25px);
	}
}
</style>