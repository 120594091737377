<template>
    <div class="panel">

        <h4 class="panel-title">Vacinação por Tipo de Colaborador</h4>

        <div class="external-container">
            <div class="chart-container">
                <canvas ref="myChart"></canvas>
            </div>
            <div class="external-container-align-circles">
                <div class="align-legend">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.32031" cy="5" r="5" fill="#2772C0" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ workers | addDots }}
                            </div>
                            <div class="format-text-sub">
                                Trabalhadores
                            </div>
                        </div>
                    </div>
                </div>
                <div class="align-legend">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.32031" cy="5" r="5" fill="#28C76F" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ dependants | addDots }}
                            </div>
                            <div class="format-text-sub">
                                Dependentes
                            </div>
                        </div>
                    </div>
                </div>
                <div class="align-legend-3">
                    <div class="align-circle-div">
                        <div class="circle-format">
                            <svg width="11" height="10" viewBox="0 0 11 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5.32031" cy="5" r="5" fill="#8C65E2" />
                            </svg>
                        </div>
                        <div class="align-circle-div-2">
                            <div class="format-text">
                                {{ thirdParty | addDots }}
                            </div>
                            <div class="format-text-sub">
                                Terceiros
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import Chart from 'chart.js';
Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.labels.usePointStyle = true;
// draws a rectangle with a rounded top
Chart.helpers.drawRoundedTopRectangle = function (ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    // top right corner
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    // bottom right   corner
    ctx.lineTo(x + width, y + height);
    // bottom left corner
    ctx.lineTo(x, y + height);
    // top left   
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
};

Chart.elements.RoundedTopRectangle = Chart.elements.Rectangle.extend({
    draw: function () {
        var ctx = this._chart.ctx;
        var vm = this._view;
        var left, right, top, bottom, signX, signY, borderSkipped;
        var borderWidth = vm.borderWidth;

        if (!vm.horizontal) {
            // bar
            left = vm.x - vm.width / 2;
            right = vm.x + vm.width / 2;
            top = vm.y;
            bottom = vm.base;
            signX = 1;
            signY = bottom > top ? 1 : -1;
            borderSkipped = vm.borderSkipped || 'bottom';
        } else {
            // horizontal bar
            left = vm.base;
            right = vm.x;
            top = vm.y - vm.height / 2;
            bottom = vm.y + vm.height / 2;
            signX = right > left ? 1 : -1;
            signY = 1;
            borderSkipped = vm.borderSkipped || 'left';
        }

        // Canvas doesn't allow us to stroke inside the width so we can
        // adjust the sizes to fit if we're setting a stroke on the line
        if (borderWidth) {
            // borderWidth shold be less than bar width and bar height.
            var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
            borderWidth = borderWidth > barSize ? barSize : borderWidth;
            var halfStroke = borderWidth / 2;
            // Adjust borderWidth when bar top position is near vm.base(zero).
            var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
            var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
            var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
            var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
            // not become a vertical line?
            if (borderLeft !== borderRight) {
                top = borderTop;
                bottom = borderBottom;
            }
            // not become a horizontal line?
            if (borderTop !== borderBottom) {
                left = borderLeft;
                right = borderRight;
            }
        }

        // calculate the bar width and roundess
        var barWidth = Math.abs(left - right);
        var roundness = this._chart.config.options.barRoundness || 0.5;
        var radius = barWidth * roundness * 0.5;

        // keep track of the original top of the bar
        var prevTop = top;

        // move the top down so there is room to draw the rounded top
        top = prevTop + radius;
        var barRadius = top - prevTop;

        ctx.beginPath();
        ctx.fillStyle = vm.backgroundColor;
        ctx.strokeStyle = vm.borderColor;
        ctx.lineWidth = borderWidth;

        // draw the rounded top rectangle
        Chart.helpers.drawRoundedTopRectangle(ctx, left, (top - barRadius + 1), barWidth, bottom - prevTop, barRadius);

        ctx.fill();
        if (borderWidth) {
            ctx.stroke();
        }

        // restore the original top value so tooltips and scales still work
        top = prevTop;
    },
});
Chart.defaults.roundedBar = Chart.helpers.clone(Chart.defaults.bar);

Chart.controllers.roundedBar = Chart.controllers.bar.extend({
    dataElementType: Chart.elements.RoundedTopRectangle
});

const customLine = Chart.helpers.line;
Chart.helpers.line = function (index, x, y, x0, y0, x1, y1) {
    const ctx = this._context;
    const isFirstLine = index === 0; // Verifica se é a primeira linha
    const lineWidth = isFirstLine ? 2 : 1; // Ajusta a espessura da primeira linha conforme necessário
    ctx.lineWidth = lineWidth;

    return customLine.apply(this, arguments);
};


Chart.scaleService.updateScaleDefaults('linear', {
    gridLines: {
        drawBorder: false,
        color: '#EBE9F1', // cor das linhas do eixo Y
        zeroLineColor: '#EBE9F1', // cor da linha zero do eixo Y
        zeroLineWidth: 2, // espessura da linha zero do eixo Y
        lineWidth: 2, // espessura das linhas do eixo Y
    },
});
export default {
    mixins: [dashUtilsMixin],
    props: {
        panelData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            myChart: null,
            animatedWorkers: 0,
            animatedDependants: 0,
            animatedThirdParty: 0
        };
    },
    watch: {
        panelData() {
            this.renderChart();
        }
    },

    computed: {
        workers() {
            return this.animatedWorkers.toFixed(0)
        },
        dependants() {
            return this.animatedDependants.toFixed(0)
        },
        thirdParty() {
            return this.animatedThirdParty.toFixed(0)
        }
    },

    // Deletar se não for usado.
    mounted() {
        this.animateNumber('animatedWorkers', this.panelData.trabalhadores)
        this.animateNumber('animatedDependants', this.panelData.dependentes)
        this.animateNumber('animatedThirdParty', this.panelData.terceiros)
        this.renderChart();
    },

    methods: {
        renderChart() {
            if (this.myChart) this.myChart.destroy()
            const ctx = this.$refs.myChart.getContext('2d');
            const config = this.getChartConfig();
            this.myChart = new Chart(ctx, config);
        },
        getChartConfig() {
            const labels = ["Trabalhadores", "Dependentes", "Terceiros"]
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Vacinados',
                        data: [this.panelData.trabalhadores, this.panelData.dependentes, this.panelData.terceiros],
                        borderColor: ['#2772C0', '#3AAA4E', '#8C65E2'],
                        backgroundColor: ['#2772C0', '#3AAA4E', '#8C65E2'],
                        barThickness: 14,
                        barPercentage: 0.8,
                        categoryPercentage: 0.5
                    },
                ],
            };
            const config = {
                type: 'roundedBar',
                data: data,
                options: {
                    interaction: {
                        mode: 'dataset'
                    },
                    barRoundness: 1,
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            gridLines: {
                                drawBorder: false,
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                display: false,
                            },

                            ticks: {
                                fontColor: '#B9B9C3',
                                fontFamily: 'Montserrat',
                                fontStyle: '400',
                                fontSize: 13,
                                // stepSize: 5,
                                maxTicksLimits: 10,
                                beginAtZero: true,
                                // min: 0,
                                // max: 10,
                                padding: 15,


                            }
                        }],
                        xAxes: [

                            {
                                display: false,
                                ticks: {
                                    fontColor: '#6E6B7B',
                                    fontFamily: 'Montserrat',
                                    fontStyle: 'normal',

                                }
                            }]
                    },
                    plugins:
                    {
                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                            }
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Line Chart',
                        },
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                var datasetLabel = '';
                                var index = tooltipItem.index;
                                if (index === 0) {
                                    datasetLabel = ' Trabalhadores';
                                } else if (index === 1) {
                                    datasetLabel = ' Dependentes';
                                } else if (index === 2) {
                                    datasetLabel = ' Terceiros';
                                }
                                return datasetLabel;
                            },
                            afterLabel: function (tooltipItem, data) {
                                return ' \n\xa0Vacinados: ' + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                            }
                        },
                        usePointStyle: true,
                        pointStyle: 'circle',
                        backgroundColor: '#FFF',
                        titleFontColor: '#5E5873',
                        bodyFontColor: '#5E5873',
                        borderColor: "#0000000F",
                        borderWidth: 1,
                        titleFontFamily: "Montserrat",
                        titleFontSize: 0,
                        xPadding: 14,
                        yPadding: 14,
                        bodyFontFamily: "Montserrat",
                        bodyFontSize: 15,
                        cornerRadius: 6,
                        padding: 20
                    },
                },
            };
            return config;
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

.align-circle-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-align: center;
}

.circle-format {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-align: center;
    padding-right: responsive-width(6px);
}

.align-legend {
    width: responsive-width(126px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding-bottom: responsive-height(23px);
}

.align-legend-3 {
    width: responsive-width(126px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;

}

.spacing {
    padding-right: responsive-width(15px);
}

.chart-container {
    width: responsive-width(274px);
    height: responsive-height(210px);

}

.external-container {

    margin: responsive-height(44px) responsive-width(44px) responsive-height(44px) responsive-width(44px);
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}


.align-circle-div-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    justify-items: center;
    text-align: center;
}

.format-text {
    color: var(--1-theme-color-dark, #5E5873);
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(20px);
    font-style: normal;
    font-weight: 500;
    line-height: responsive-height(24px);
}

.format-text-sub {
    color: var(--1-theme-color-dark, #6E6B7B);
    text-align: start;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(14px);
    font-style: normal;
    font-weight: 400;
    line-height: responsive-height(21px);
}

.external-container-align-circles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin-left: responsive-width(60px);

}
</style>