<template>
    <div v-if="absoluteData" class="panel absolute-data-panel">
        <img :src="imgPath" alt="Icone Dashboard empresa" class="panel-icon">
        <div>
            <h2 class="panel-value">{{ absoluteData.value | addDots }}</h2>
            <h5 class="panel-label">{{ absoluteData.label[0] }}<br> {{ absoluteData.label[1] }}</h5>
        </div>
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

export default {
    mixins: [ dashUtilsMixin ],

    props: {
        absoluteData: {
            type: Object,
            required: true
        }
    },
    computed: {
        imgPath() {
            return require(`@/assets/custom-icons/pages/dashboard/${this.absoluteData.img}.png`)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* CSS RESET */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.absolute-data-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: responsive-height(173px) !important;

    .panel-icon {
        margin-right: responsive-width(24px);
    }

    .panel-label {
        font-weight: 500;
        line-height: responsive-height(18px);
        color: #babfc7;
    }
}
</style>