<template>
    <transition name="fade">
		<NoCampaignFound v-if="!isLoading && !campaignExists" />
		
		<div v-if="!isLoading && campaignExists">
			<DashboardHeader :updatedAt="updatedAt"/>

			<DashboardFilter 
				ref = "filterComponent" 
				:filterFields = "filterFields"
				v-model = "filterSearchParams"
				@filterUpdade = "updateDashboardData($event)" />
			
			<transition name="fade" mode="out-in">
				<b-spinner class="data-load-spinner" v-if="!dataLoaded"/>
				<DashboardData v-else-if="dashboardHasData()" 
					:chartsData="dashboardData" 
					:tableData="tableData" 
					:filterParams="filterSearchParams"/>
				<NoDataFound v-else />
			</transition>
		</div>
	</transition>
</template>

<script>
import dashInitializationMixin from "@/views/custom-pages/dashboard/SESI-user/components-SESI-user/dashInitializationMixin"
import DashboardHeader from '@/views/custom-pages/dashboard/shared-components/DashboardHeader.vue'
import DashboardFilter from '@/views/custom-pages/dashboard/SESI-user/components-DR-UO-vaccinator/DashboardFilter.vue'
import DashboardData from '@/views/custom-pages/dashboard/SESI-user/vaccinator/vaccinator-components/DashboardData.vue'

export default {
    components: {
		DashboardHeader,
		DashboardFilter,
        DashboardData
    },

	mixins: [ dashInitializationMixin ],

	methods: {
		// Returns true if ANY of the conditions is true. Otherwise, returns false.
		dashboardHasData() {
			return (
				this.dashboardData.vacinas_aplicadas.some(vacina => vacina.quantidade > 0) ||
				this.dashboardData.vacinacao_por_colaborador.trabalhadores > 0 ||
				this.dashboardData.vacinacao_por_colaborador.dependentes > 0 ||
				this.dashboardData.vacinacao_por_colaborador.terceiros > 0 ||
				this.tableData.companies.some(company => company.vaccinatedEmployees > 0 || company.remainingDoses > 0)
			)
		}
	}
}
</script>