<template>
    <b-alert variant="danger" :show="true">

        <div class="alert-heading d-flex align-items-center">
            <img class="notification-img" :src="redExclamationImg" alt="alert-red-exclamation">
            <h4 class="notification-title text-danger">
                {{ title }}
            </h4>
            <span class="dismiss" @click="closeNotification()">&#10005;</span> 
        </div>

        <div class="alert-body">
            <span class="text-danger font-weight-normal">
                <span v-html="message"></span>
            </span>
        </div>

    </b-alert>
</template>
  
<script>
import { BAlert } from 'bootstrap-vue';

export default {
    components: {
        BAlert
    },

    props: {
        value: {
            type: Object | null,
            required: true
        },
        notificationType: {
            type: Number,
            required: true
        }
    },

    computed: {
        title() {
            if (this.type == 1) return "Filtros modificados"
            else return "Data inválida"
        },
        message() {
            if (this.type == 1) {
                return "Alguns filtros do seu painel foram modificados e as informações não foram atualizadas. Clique no botão <b>Filtrar</b> para atualizar os dados."
            } else if (this.type == 2) {
                return "A data informada é inválida! Informe um período entre <b>1º de Janeiro de 2022</b> e a <b>data atual.</b>" 
            } else {
                return "A data informada é inválida! A <b>data inicial</b> não pode ser <b>maior que</b> a <b>data final.</b>" 
            } 
        }
    },

    data() {
        return {
            type: this.notificationType,
            redExclamationImg: require(`@/assets/custom-icons/pages/dashboard/alert-red-exclamation.png`),
        }
    },

    watch: {
        notificationType() {
            this.type = this.notificationType
        }
    },

    methods: {
        closeNotification() {
            this.$emit("input", false)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.notification-img {
    margin-right: 10px;
}

.notification-title {
    font-weight: 700;
    margin-bottom: 0;
}

.text-danger {
    font-size: responsive-height(14px);
    line-height: responsive-height(20px);
}

.dismiss {
    margin-left: auto;
    cursor: pointer;
}

// MOBILE
@media only screen and (max-width: 780px) {
    .alert-body {
        text-align: center;
    }
}
</style>