import { BSpinner } from "bootstrap-vue";
import {actions, subjects} from "@/libs/acl/rules";
import { warningMessage } from '@/libs/sweetalerts'
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import NoCampaignFound from '@/views/custom-pages/dashboard/shared-components/NoCampaignFound.vue'
import NoDataFound from '@/views/custom-pages/dashboard/shared-components/NoDataFound.vue'

export default {
	components: {
		BSpinner,
		NoCampaignFound,
		NoDataFound
	},

	mixins: [ dashUtilsMixin ],

    data() {
		return {
			isLoading: true,
			perfil: "",
            perfilRoute: "",
			filterFields: {},
			campaignExists: true,
			filterSearchParams: {},
            updatedAt: `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`,
			dashboardData: {},
			tableData: {},
            beforeFirstFilter: true,
			dataLoaded: false,
			vaccinatorDash: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_VACINADOR),
			dnDashboard: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_DN),
		}
	},

    created() {
		if(this.vaccinatorDash){
			this.perfilRoute = "vacinador"
		}else{
			this.perfilRoute = "dn-dr-uo"
		}
    },

	beforeMount() {
		this.populateFilterFields()
	},

    methods: {
		 populateFilterFields() {
			this.$http.get(this.$api.getDashboardFilterOptions(this.perfilRoute)).then(({data}) => {
				this.filterFields = data
				const filterParams = this.populateSearchParams(data)
				if (!this.dnDashboard) {
					this.updateDashboardData(filterParams)
				}
				this.$emit("loadFinished", true)
				this.isLoading = false
			}).catch(() => {
				warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
			})
		},

		populateSearchParams(data) {
			const allDR = data.userDR
			const allUO = data.userUO.sort((a, b) => a.id - b.id)

			if (data.userCampaigns.length == 0) {
				this.campaignExists = false
				return
			}

			const allCampaigns = data.userCampaigns.sort((a, b) => a.id - b.id)

			const filterParams = {
				dr: allDR.map(dr => dr.id),
                uo: allUO.map(uo => uo.id),
                campaigns: allCampaigns.map(uo => uo.id),
				startDate: `01/01/${moment().year()}`,
                endDate: moment().format('DD/MM/YYYY')
			}

			this.filterSearchParams = filterParams

			return filterParams;
		},

		updateDashboardData(filterParams) {
			if (!this.campaignExists) return
			this.dataLoaded = false
			let params = { ...filterParams }
			params.startDate = this.standardDateFormat(params.startDate)
			params.endDate = this.standardDateFormat(params.endDate)

			const headers = {
				'department': params.dr,
				'uo': params.uo,
				'campaigns': params.campaigns,
				'startDate': params.startDate,
				'endDate': params.endDate,
				'units': params.uo,
				'page': params.page ? params.page : 0,
				'orderBy': params.orderBy ? params.orderBy : 'name',
				'orderType': params.orderType ? params.orderType : 'DESC'
			}

			this.beforeFirstFilter = false

			this.functionGetDashboardData(headers);

			if (this.vaccinatorDash) {
				this.functionGetVaccinatorDashboardTableData(headers);
			}
		},
		functionGetDashboardData(headers){
			this.$http.get(this.$api.getDashboardData(this.perfilRoute), { headers }).then(({data}) => {
				this.dashboardData = data
				this.updatedAt = `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`
				if (!this.isLoading) this.$refs.filterComponent.generateActiveFilterTags();
				this.dataLoaded = true
			})
		},
		functionGetVaccinatorDashboardTableData(headers){
			this.$http.get(this.$api.getVaccinatorDashboardTableData(), { headers }).then(({data}) => {
				this.tableData = data
			})
		},
		dashboardHasData() {
			return !!this.dashboardData.vacinacao
		}
	}
}