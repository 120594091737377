<template>
    <transition name="fade">
		<NoCampaignFound v-if="!isLoading && !campaignExists" />

		<div v-if="!isLoading && campaignExists">
			<DashboardHeader :updatedAt="updatedAt"/>

			<DashboardFilter 
				ref = "filterComponent" 
				:filterFields = "filterFields"
				v-model = "filterSearchParams"
				@filterUpdade = "updateDashboardData($event)" />
			
			<transition name="fade" mode="out-in">
				<BeforeFirstFiltrage v-if="beforeFirstFilter"/>
				<b-spinner class="data-load-spinner" v-else-if="!dataLoaded"/>
				<DashboardData v-else-if="dashboardHasData()" :dashData="dashboardData" :filterParams="filterSearchParams"/>
				<NoDataFound v-else />
			</transition>
		</div>
	</transition>
</template>

<script>

import dashInitializationMixin from "@/views/custom-pages/dashboard/SESI-user/components-SESI-user/dashInitializationMixin"
import DashboardHeader from '@/views/custom-pages/dashboard/shared-components/DashboardHeader.vue'
import DashboardFilter from '@/views/custom-pages/dashboard/SESI-user/DN/DN-components/DashboardFilter.vue'
import DashboardData from '@/views/custom-pages/dashboard/SESI-user/components-DN-DR-UO/DashboardData.vue'
import BeforeFirstFiltrage from '@/views/custom-pages/dashboard/SESI-user/DN/DN-components/BeforeFirstFiltrage.vue'

export default {
    components: {
		DashboardHeader,
		DashboardFilter,
        DashboardData,
		BeforeFirstFiltrage
    },

	mixins: [ dashInitializationMixin ]
}
</script>