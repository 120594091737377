<template>
    <div class="dashboard-general-data">
        <div class="general-data row-1">
            <TotalVaccinatedPanel :panelData="dashData.vacinacao" />
            <CompanyAdhesionPanel :panelData="dashData.adesoes_por_empresas" />
            <DoseAdhesionPanel :panelData="dashData.doses_aderidas" />
            <DoseDistributionPanel :panelData="dashData.distribuicao_doses" v-if="showDoseDistribution" />
        </div>

        <div class="general-data row-2">
            <VaccinationTypePanel :panelData="dashData.adesoes_por_vacinas">
                <h4 class="panel-title">Adesões por Vacinas</h4>
            </VaccinationTypePanel>

            <VaccinationPerCollaboratorPanel :panelData="dashData.vacinacao_por_colaborador" />
        </div>
    </div>
</template>

<script>
import TotalVaccinatedPanel from "./data-panels/TotalVaccinatedPanel.vue"
import CompanyAdhesionPanel from "./data-panels/CompanyAdhesionPanel.vue"
import DoseAdhesionPanel from './data-panels/DoseAdhesionPanel.vue'
import DoseDistributionPanel from './data-panels/DoseDistributionPanel.vue'
import VaccinationTypePanel from '@/views/custom-pages/dashboard/SESI-user/components-SESI-user/VaccinationTypePanel.vue'
import VaccinationPerCollaboratorPanel from '@/views/custom-pages/dashboard/SESI-user/components-SESI-user/VaccinationPerCollaboratorPanel.vue'

export default {
    components: {
        TotalVaccinatedPanel,
        CompanyAdhesionPanel,
        DoseAdhesionPanel,
        DoseDistributionPanel,
        VaccinationTypePanel,
        VaccinationPerCollaboratorPanel
    },

    props: {
        dashData: {
            type: Object,
            required: true
        },
        filterParams: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showDoseDistribution: this.filterParams.campaigns.length == 1
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.row-1 .panel { height: responsive-height(251px); }
.row-2 .panel { height: responsive-height(348px); }
</style>

<style lang="scss">
@import "@/assets/scss/_responsivity.scss";

.vaccination, .doses-adherence { flex-basis: responsive-width(243px); }
.company-adherence, .doses-distributed { flex-basis: responsive-width(282px); }

/* DATA */
.vaccination, .doses-adherence, .company-adherence {
    .content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .panel-content {
        margin-top: responsive-height(36px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .panel-icon {
        margin-bottom: responsive-height(5px);
    }

    .panel-label {
        text-align: center;
    }
}


// MOBILE
@media only screen and (max-width: 780px) {
    .row-1 .panel { 
        min-width: 100%; 
    }

    .vaccination, .doses-adherence {
        .panel-content {
            margin-top: 10px;
        }

        .panel-icon {
            position: absolute;
            margin-right: 40%;
        }

        .panel-value, .panel-label {
            margin-left: 40%;
        }
    }

    .company-adherence .panel-content {
        margin-top: 10px;
    }
}
</style>