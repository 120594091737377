<template>
    <div class="panel vaccination">

        <h4 class="panel-title">Vacinação</h4>

        <div class="panel-content">
            <img class="panel-icon" :src="vaccinationImg" alt="ícone colaboradores vacinados">
            <h2 class="panel-value">{{ vaccinatedCollaborators | addDots }}</h2>
            <h5 class="panel-label">Colaboradores<br>Vacinados</h5>
        </div>
        
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

export default {
    mixins: [ dashUtilsMixin ],

    props: {
        panelData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            vaccinationImg: require(`@/assets/custom-icons/pages/dashboard/people-blue-icon.png`),
            animatedVaccinatedCollaborators: 0
        }
    },

    computed: {
        vaccinatedCollaborators() {
            return this.animatedVaccinatedCollaborators.toFixed(0)
        }
    },

    mounted() {
        this.animateNumber('animatedVaccinatedCollaborators', this.panelData.colaboradores_vacinados)
    }
}
</script>