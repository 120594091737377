import axiosIns from '@/libs/axios'
import rotasAPI from '@/router/rotasAPI'


// Dashboard Filter Fields
export const getDashboardFilterOptions = (perfil) => new Promise((resolve, reject) => {
    axiosIns
        .get(rotasAPI.getDashboardFilterOptions(perfil))
        .then(response => resolve(response))
        .catch(error => reject(error))
})


// Dashboard Data
export const getDashboardData = (perfil, params) => new Promise((resolve, reject) => {
    const headers = {
        'department': params.dr,
        'uo': params.uo,
        'campaigns': params.campaigns,
        'startDate': params.startDate,
        'endDate': params.endDate
    }

    axiosIns
        .get(rotasAPI.getDashboardData(perfil), { headers })
        .then(response => resolve(response))
        .catch(error => reject(error))
})


// Vaccinator Dashboard Table Data
export const getVaccinatorTableData = (params) => new Promise((resolve, reject) => {
    let headers = {
        'startDate': params.startDate,
        'endDate': params.endDate,
        'campaigns': params.campaigns,
        'units': params.uo
    }

    if (params.page != null) headers["page"] = params.page
    if (params.orderBy != null) headers["orderBy"] = params.orderBy
    if (params.orderType != null) headers["orderType"] = params.orderType

    axiosIns
        .get(rotasAPI.getVaccinatorDashboardTableData(), { headers })
        .then(response => resolve(response))
        .catch(error => reject(error))
})