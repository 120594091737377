<template>
    <div class="panel doses-adherence">

        <h4 class="panel-title">Doses Aderidas</h4>

        <div class="panel-content">
            <img class="panel-icon" :src="doseAdhesionImg" alt="ícone total de doses">
            <h2 class="panel-value">{{ totalDoses | addDots }}</h2>
            <h5 class="panel-label">Total de<br>Doses</h5>
        </div>
        
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

export default {
    mixins: [ dashUtilsMixin ],
    
    props: {
        panelData: {
            type: Object,
            required: true
        }
    },
    
    data() {
        return {
            doseAdhesionImg: require(`@/assets/custom-icons/pages/dashboard/purple-vaccine-icon.png`),
            animatedTotalDoses: 0
        }
    },

    computed: {
        totalDoses() {
            return this.animatedTotalDoses.toFixed(0)
        }
    },

    mounted() {
        this.animateNumber('animatedTotalDoses', this.panelData.total_doses)
    }
}
</script>