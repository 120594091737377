<template>
    <div>
        <div class="dashboard-loading" v-if="isLoading">
            <b-spinner/>
        </div>
    
        <CompanyDashboard v-if="perfilEmpresa" @loadFinished="isLoading = false" />
        <DnDashboard v-else-if="perfilDN" @loadFinished="isLoading = false" />
        <DrDashboard v-else-if="perfilDR" @loadFinished="isLoading = false" />
        <UoDashboard v-else-if="perfilUO" @loadFinished="isLoading = false" />
        <VaccinatorDashboard v-else-if="perfilVacinador" @loadFinished="isLoading = false" />
    </div>
</template>

<script>
import { actions, subjects } from '@/libs/acl/rules'
import { BSpinner } from "bootstrap-vue";
import CompanyDashboard from '@/views/custom-pages/dashboard/company/CompanyDashboard.vue'
import DnDashboard from '@/views/custom-pages/dashboard/SESI-user/DN/DnDashboard.vue'
import DrDashboard from '@/views/custom-pages/dashboard/SESI-user/DR/DrDashboard.vue'
import UoDashboard from '@/views/custom-pages/dashboard/SESI-user/UO/UoDashboard.vue'
import VaccinatorDashboard from '@/views/custom-pages/dashboard/SESI-user/vaccinator/VaccinatorDashboard.vue'

export default {
    components: {
        BSpinner,
        CompanyDashboard,
        DnDashboard,
        DrDashboard,
        UoDashboard,
        VaccinatorDashboard
    },

    data() {
        return {
            isLoading: true,

            perfilEmpresa: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_EMPRESA),
            perfilDN: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_DN),
            perfilDR: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_DR),
            perfilUO: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_UO),
            perfilVacinador: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_VACINADOR),
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";
@import "@/views/custom-pages/dashboard/shared-styles/dashboard-filter.scss";
@import "@/views/custom-pages/dashboard/shared-styles/dashboard-data.scss";

/* GENERAL DASHBOARD STYLES */
.dashboard-loading {
	height: 80vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

    .spinner-border {
        color: $custom-blue;
        width: 4rem;
        height: 4rem;
    }
}

.data-load-spinner.spinner-border {
    display: block;
    margin: auto;
    margin-top: responsive-height(150px);
    color: $custom-blue;
    width: 4rem;
    height: 4rem;
}


// MOBILE
@media only screen and (max-width: 780px) {
    .no-data-found, .before-filtrage {
        margin-bottom: 28px;
    }

    .data-load-spinner.spinner-border { 
        margin-top: mobile-responsive-height(35px); 
    }
}
</style>