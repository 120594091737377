<template>
    <div class="dashboard-filter panel">
        <transition-group name="notification">

            <FilterNotification 
                :key="'notification'"
                v-if="this.filterChanges && this.showNotification"
                :notificationType="notificationType" 
                v-model="showNotification"/>

            <div class="data-filter" :key="'filter-fields'">
                <div class="filter-selection">
                    <MultipleSelectFilter 
                        v-if="!perfilAdminUnidade"
                        :field = "'Unidade Operacional'"
                        :optionsDropdown = "uoDropdown"
                        v-model = "selectedUO"/>
    
                    <SingleSelectFilter 
                        :field = "'Campanha'"
                        :optionsDropdown = "campaignsDropdown"
                        :deselectable = true
                        v-model = "selectedCampaign"/>
                    
                    <DateFilter 
                        inputDateType = "start-date" 
                        v-model = "selectedStartDate"/>
    
                    <DateFilter 
                        inputDateType = "end-date" 
                        v-model = "selectedEndDate"/>
                </div>
    
                <div class="filter-buttons">
                    <button class="filter-btn" @click="filterData()">Filtrar</button>
                    <button class="reset-btn" @click="resetFilters()">Limpar</button>
                </div>
            </div>
    
            <FilterTags :allTags = "activeFilterTags" :key="'tags'"/>

        </transition-group>
    </div>
</template>

<script>
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import DateFilter from "@/views/custom-pages/dashboard/shared-components/DateFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"
import FilterNotification from '@/views/custom-pages/dashboard/shared-components/FilterNotification.vue';

export default {
    components: {
        SingleSelectFilter,
        MultipleSelectFilter,
        DateFilter,
        FilterTags,
        FilterNotification
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            perfilAdminUnidade: false,
            selectedUO: [],
            selectedCampaign: null,
            selectedStartDate: `01/01/${moment().year()}`,
            selectedEndDate: moment().format('DD/MM/YYYY'),
            filterSelectedOptions: this.value,
            currentFilteredParams: this.value,
            afterFirstFilter: false,
            showNotification: true,
            notificationType: 1,       // 1 - Filter Changes      2 - Invalid Data
            activeFilterTags: [],
        }
    },

    computed: {
        selectedDR() {
            return this.filterFields.userDR[0]
        },
        uoDropdown() {
            let uoList = this.filterFields.userUO
            this.orderAlphabetically(uoList)

            return uoList
        },

        campaignsDropdown() {
            let campaignsList = this.filterFields.userCampaigns
            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)

            return campaignsList
        },

        // If 0 Operational Units were selected, the entire list is filtered. Otherwise, only the selected Operational Units are filtered.
        uoToFilter() {
            if (this.selectedUO.length == 0) return [...this.uoDropdown].sort((a, b) => a.id - b.id)
            else return [...this.selectedUO].sort((a, b) => a.id - b.id)
        },

        // If no campaign was selected, the entire list is filtered. Otherwise, only the selected campaign is filtered.
        campaignsToFilter() {
            if (this.selectedCampaign) return [this.selectedCampaign]
            else return [...this.campaignsDropdown].sort((a, b) => a.id - b.id)
        },

        filterChanges() {
            this.filterSelectedOptions = {
                dr: [this.selectedDR.id],
                uo: this.uoToFilter.map(uo => uo.id),
                campaigns: this.campaignsToFilter.map(campaign => campaign.id),
                startDate: this.selectedStartDate,
                endDate: this.selectedEndDate
            }

            const currentDataFilterContent = JSON.stringify(this.currentFilteredParams)
            const selectedOptionsContent = JSON.stringify(this.filterSelectedOptions)

            this.notificationType = 1
            this.showNotification = true
            
            return selectedOptionsContent !== currentDataFilterContent
        }
    },

    watch: { 
        value() {
            this.currentFilteredParams = this.value
        }
    },

    created() {
        const userData = this.$store.getters['sessions/getUserData']
        this.perfilAdminUnidade = userData.perfis[0].chave == "ADMIN_UNIDADE"

        if (this.perfilAdminUnidade) this.selectedUO = this.filterFields.userUO
    },

    beforeMount() {
        this.generateActiveFilterTags()
    },

    methods: { 
        filterData() {
            if (!this.validateDate(this.selectedStartDate) || !this.validateDate(this.selectedEndDate)) {
                this.notificationType = 2
                this.showNotification = true
                return
            } else if (this.startGreaterThanEndDate(this.selectedStartDate, this.selectedEndDate)) {
                this.notificationType = 3
                this.showNotification = true
                return
            }

            this.currentFilteredParams = this.filterSelectedOptions
            this.afterFirstFilter = true
            
            this.$emit("input", this.currentFilteredParams)
            this.$emit("filterUpdade", this.currentFilteredParams)
        },

        resetFilters() {
            this.selectedUO = this.perfilAdminUnidade ? this.filterFields.userUO : []
            this.selectedCampaign = null
            this.selectedStartDate = `01/01/${moment().year()}`
            this.selectedEndDate = moment().format('DD/MM/YYYY')
        },

        generateActiveFilterTags() {
            this.activeFilterTags = []
            let tagText = ""
            let tagTitle = ""
            
            let uoTags = [...this.uoToFilter]
            this.orderAlphabetically(uoTags)

            // Less than 10 Companies = 1 tag for each.
            if (uoTags.length <= 10) {
                for (const uo of uoTags) {
                    tagText = tagTitle = uo.name
                    this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            
            // More than 10 UO = 1 tag for All UO.
            } else {
                tagText = `${uoTags.length} Unidades`

                for (const uo of uoTags) {
                    tagTitle += `${uo.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
            
            if (this.selectedCampaign != null) {
                tagText = tagTitle = `${this.selectedCampaign.name} - Início em ${this.displayDateFormat(this.selectedCampaign.startDate)}`
            } else {
                tagText = tagTitle = "Todas as Campanhas"
            }
            this.activeFilterTags.push({ text: tagText, title: tagTitle })

            tagText = tagTitle = `${this.selectedStartDate} até ${this.selectedEndDate}`
            this.activeFilterTags.push({ text: tagText, title: tagTitle })
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.dashboard-filter {
    position: relative;
	margin-bottom: responsive-height(25px);
}

/* Notification Animation */
.notification-enter, .notification-leave-to {
    opacity: 0; 
}

.notification-move, .notification-enter-active {
    transition: all .3s;
}

.notification-leave-active {
    position: absolute;
    width: calc(100% - 4.6vh);
    transition: all .3s;
}
</style>