<template>
    <div class="panel">
        <h4 class="panel-title">Evolução da Vacinação</h4>

        <div class="chart-container">
            <div class="align-legend">
                <div class="align-circle-div">
                    <div class="circle-format">
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5.32031" cy="5" r="5" fill="#2772C0" />
                        </svg>
                    </div>
                    <div class="format-text">
                        Pessoas vacinadas
                    </div>
                </div>
            </div>
            <canvas ref="myChart"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.labels.usePointStyle = true;
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },

    computed: {
        numWeek() {
            return this.data.numWeek
        },
        dataWeek() {
            return this.data.dataWeek
        },
        subWeek() {
            return this.data.subWeek
        },
        historicoCompleto() {
            return this.data.historicoCompleto
        }
    },

    data() {
        return {
            myChart: null,
        };
    },

    watch: {
        data() {
            this.renderChart();
        }
    },

    mounted() {
        this.renderChart();
    },
    
    methods: {
        renderChart() {
            if (this.myChart) this.myChart.destroy()
            const ctx = this.$refs.myChart.getContext('2d');
            const config = this.getChartConfig();
            this.myChart = new Chart(ctx, config);
        },
        getChartConfig() {
            const space = " ";
            const labels = this.numWeek.map((week, index) => ["Semana " + (index + 1),space +this.subWeek[index]]);
            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Pessoas vacinadas',
                        data: this.dataWeek,
                        borderColor: '#2772C0',
                        backgroundColor: 'rgba(255, 0, 0, 0)',
                        borderWidth: 4,
                        pointHoverBorderColor: '#2772C0',
                        hoverBorderColor: '#D9D9D9',
                        hoverBorderWidth: 5,
                        pointBorderColor: '#2772C0',
                        pointBackgroundColor: '#2772C0', // Cor do ponto
                    },
                ],
            };
            const config = {
                type: 'line',
                data: data,
                options: {
                    interaction: {
                        mode: 'dataset'
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontColor: '#6E6B7B',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: '#6E6B7B',
                                fontFamily: 'Montserrat',
                                fontStyle: 'normal',
                            }
                        }]
                    },
                    plugins:
                    {
                        legend: {
                            display: true,
                            labels: {
                                usePointStyle: true,
                            }
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Line Chart',
                        },
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        position: 'nearest',
                        callbacks: {
                            afterLabel: (tooltipItem, data) => {
                                const legenda = this.historicoCompleto[tooltipItem['index']];
                                return "Período: " + legenda;
                            }
                        },
                        usePointStyle: true,
                        pointStyle: 'circle',
                        backgroundColor: '#FFF',
                        titleFontColor: '#5E5873',
                        bodyFontColor: '#5E5873',
                        borderColor: "#2772C0",
                        borderWidth: 1,
                        titleFontFamily: "Montserrat",
                        titleFontSize: 12,
                        bodyFontFamily: "Montserrat",
                        bodyFontSize: 12,
                        cornerRadius: 6,
                    },

                },
            };
            return config;
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

/* CSS RESET */
* {
    margin: 0;
    padding: 0;
    border: 0;
}

.align-circle-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-align: center;
}

.circle-format {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    text-align: center;
    padding-right: responsive-width(6px);
}

.format-text {
    color: var(--1-theme-color-dark, #4B4B4B);
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: responsive-height(12px);
    font-style: normal;
    font-weight: 400;
    line-height: responsive-height(18px);
}

.align-legend {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding-bottom: responsive-height(23px);
}

.spacing {
    padding-right: responsive-width(15px);
}

.chart-container {
    margin-top: responsive-height(38px);
    width: 100%;
    height: responsive-height(283px);
}

// MOBILE
@media only screen and (max-width: 780px) {
    .chart-container {
        margin-top: 20px;
    }
}
</style>