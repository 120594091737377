<template>
    <div class="dashboard-general-data">
        <div class="general-data row-1">
            <VaccinationTypePanel :panelData="chartsData.vacinas_aplicadas">
                <h4 class="panel-title">Vacinas Aplicadas</h4>
            </VaccinationTypePanel>
    
            <VaccinationPerCollaboratorPanel :panelData="chartsData.vacinacao_por_colaborador" />
        </div>

        <div class="general-data row-2">
            <VaccinationPerCompanyPanel :panelData="tableData" :currentFilteredParams="filterParams"/>
        </div>
    </div>
</template>

<script>
import VaccinationTypePanel from '@/views/custom-pages/dashboard/SESI-user/components-SESI-user/VaccinationTypePanel.vue'
import VaccinationPerCollaboratorPanel from '@/views/custom-pages/dashboard/SESI-user/components-SESI-user/VaccinationPerCollaboratorPanel.vue'
import VaccinationPerCompanyPanel from './VaccinationPerCompanyPanel.vue'

export default {
    components: {
        VaccinationTypePanel,
        VaccinationPerCollaboratorPanel,
        VaccinationPerCompanyPanel
    },

    props: {
        filterParams: {
            type: Object,
            required: true
        },
        chartsData: {
            type: Object,
            required: true
        },
        tableData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.row-1 .panel { height: responsive-height(348px); }
.row-2 .panel { min-height: responsive-height(348px);}
</style>