<template>
    <div class="dashboard-header">
        <h1 class="title">{{ title }}</h1>
        <p class="updated-last"><span>Última Atualização:</span> {{ updatedAt }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Painel de Indicadores"
        },
        updatedAt: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

.dashboard-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: responsive-height(19px);
}

.title {
	font-size: responsive-height(49px);
	line-height: responsive-height(60px);
	color: $custom-blue;
	font-weight: 400;
	margin: 0;
}

.updated-last {
	font-size: responsive-height(16px);
	line-height: responsive-height(20px);
	font-weight: 400;
	color: $placeholder-color;
	margin: 0;

	span {
		font-weight: 600;
	}
}

@media only screen and (max-width: 780px) {
	.dashboard-header {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: mobile-responsive-height(10px);
	}

	.title {
		font-size: mobile-responsive-height(24px);
		line-height: mobile-responsive-height(28px);
	}

	.updated-last {
		font-size: mobile-responsive-height(12px);
		line-height: mobile-responsive-height(16px);
	}
}
</style>