<template>
    <div class="dashboard-filter panel">
        <transition-group name="notification">

            <FilterNotification 
                :key="'notification'"
                v-if="notificationExists"
                :notificationType="notificationType" 
                v-model="showNotification"/>

            <div class="data-filter" :key="'filter-fields'">
                <div class="filter-selection">
                    <SingleSelectFilter 
                        :field = "'Departamento Regional'"
                        :optionsDropdown = "drDropdown"
                        :deselectable = true
                        v-model = "selectedDR"/>
                    
                    <MultipleSelectFilter 
                        :field = "'Unidade Operacional'"
                        :optionsDropdown = "uoDropdown" 
                        v-model = "selectedUO"/>

                    <SingleSelectFilter 
                        :field = "'Campanha'"
                        :optionsDropdown = "campaignsDropdown"
                        :deselectable = true
                        v-model = "selectedCampaign"/>
                    
                    <DateFilter 
                        inputDateType = "start-date" 
                        v-model = "selectedStartDate" />

                    <DateFilter 
                        inputDateType = "end-date" 
                        v-model = "selectedEndDate" />
                </div>
            </div>

            <FilterTags :allTags = "activeFilterTags" :key="'tags'"/>
            
            <div class="filter-buttons" :key="'buttons'">
                <button class="filter-btn" @click="filterData()">Filtrar</button>
                <button class="reset-btn" @click="resetFilters()">Limpar</button>
            </div>

        </transition-group>
    </div>
</template>

<script>
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import SingleSelectFilter from "@/views/custom-pages/dashboard/shared-components/SingleSelectFilter.vue"
import MultipleSelectFilter from "@/views/custom-pages/dashboard/shared-components/MultipleSelectFilter.vue"
import DateFilter from "@/views/custom-pages/dashboard/shared-components/DateFilter.vue"
import FilterTags from "@/views/custom-pages/dashboard/shared-components/FilterTags.vue"
import FilterNotification from '@/views/custom-pages/dashboard/shared-components/FilterNotification.vue';

export default {
    components: {
        SingleSelectFilter,
        MultipleSelectFilter,
        DateFilter,
        FilterTags,
        FilterNotification
    },

    mixins: [ dashUtilsMixin ],

    props: {
        filterFields: {
            type: Object,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedDR: null,
            selectedUO: [],
            selectedCampaign: null,
            selectedStartDate: `01/01/${moment().year()}`,
            selectedEndDate: moment().format('DD/MM/YYYY'),
            filterSelectedOptions: this.value,
            currentFilteredParams: this.value,
            uoToFilter: this.filterFields.userUO,
            afterFirstFilter: false,
            showNotification: true,
            notificationType: 1,       // 1 - Filter Changes      2 - Invalid Data
            activeFilterTags: []
        }
    },

    computed: {
        drDropdown() {
            let drList = this.filterFields.userDR
            this.orderAlphabetically(drList)
            return drList
        },

        uoDropdown() {
            let uoList = []

            if (!this.selectedDR) {
                uoList = this.filterFields.userUO
            } else {
                let uoFromSelectedDR = this.selectedDR.UOinDR

                for (const uo of this.filterFields.userUO) {
                    if (uoFromSelectedDR.includes(uo.id)) uoList.push(uo)
                }

                // Updating the selected UO Array to include only UO from the currently selected DR.
                this.selectedUO = this.selectedUO.filter(
                    uo => uoList.some(selected => uo.id == selected.id)
                )
            }

            this.orderAlphabetically(uoList)
            return uoList
        },

        campaignsDropdown() {
            let campaignsList = []

            if (!this.selectedDR) {
                campaignsList = this.filterFields.userCampaigns
            } else {
                let campaignsFromSelectedDR = this.selectedDR.campaignsInDR

                for (const campaign of this.filterFields.userCampaigns) {
                    if (campaignsFromSelectedDR.includes(campaign.id)) campaignsList.push(campaign)
                }

                // Updating the selected Campaign to be from the currently selected DR.
                if (this.selectedCampaign && !campaignsFromSelectedDR.includes(this.selectedCampaign.id)) {
                    this.selectedCampaign = null
                }
            }

            this.orderAlphabetically(campaignsList)
            this.orderByRecentDate(campaignsList)
            return campaignsList
        },

        // If no DR was selected, the entire list is filtered. Otherwise, only the selected DR is filtered.
        drToFilter() {
            if (this.selectedDR) return [this.selectedDR]
            else return [...this.drDropdown].sort((a, b) => a.id - b.id)
        },

        // If no campaign was selected, the entire list is filtered. Otherwise, only the selected campaign is filtered.
        campaignsToFilter() {
            if (this.selectedCampaign) return [this.selectedCampaign]
            else return [...this.campaignsDropdown].sort((a, b) => a.id - b.id)
        },

        notificationExists() {
            if (this.notificationType == 1) return this.filterChanges && this.showNotification && this.afterFirstFilter
            else return this.filterChanges && this.showNotification
        },

        filterChanges() {
            this.filterSelectedOptions = {
                dr: this.drToFilter.map(dr => dr.id),
                uo: this.uoToFilter.map(uo => uo.id),
                campaigns: this.campaignsToFilter.map(campaign => campaign.id),
                startDate: this.selectedStartDate,
                endDate: this.selectedEndDate
            }
            
            const currentDataFilterContent = JSON.stringify(this.currentFilteredParams)
            const selectedOptionsContent = JSON.stringify(this.filterSelectedOptions)
            
            this.notificationType = 1
            this.showNotification = true

            return selectedOptionsContent !== currentDataFilterContent
        }
    },

    watch: { 
        value() {
            this.currentFilteredParams = this.value
        },

        // If 0 Operational Units were selected, the entire list is filtered. Otherwise, only the selected Operational Units are filtered.
        selectedUO() {
            if (this.selectedUO.length == 0) this.uoToFilter = [...this.uoDropdown]
            else this.uoToFilter = [...this.selectedUO]
            this.uoToFilter.sort((a, b) => a.id - b.id)
        }
    },

    methods: { 
        filterData() {
            if (!this.validateDate(this.selectedStartDate) || !this.validateDate(this.selectedEndDate)) {
                this.notificationType = 2
                this.showNotification = true
                return
            } else if (this.startGreaterThanEndDate(this.selectedStartDate, this.selectedEndDate)) {
                this.notificationType = 3
                this.showNotification = true
                return
            }

            this.currentFilteredParams = this.filterSelectedOptions
            this.afterFirstFilter = true

            this.$emit("input", this.currentFilteredParams)
            this.$emit("filterUpdade", this.currentFilteredParams)
        },

        resetFilters() {
            this.selectedDR = null
            this.selectedUO = []
            this.selectedCampaign = null
            this.selectedStartDate = `01/01/${moment().year()}`
            this.selectedEndDate = moment().format('DD/MM/YYYY')
        },

        generateActiveFilterTags() {
            this.activeFilterTags = []
            let tagText = ""
            let tagTitle = ""
            
            if (this.selectedDR != null) tagText = tagTitle = this.selectedDR.name
            else tagText = tagTitle = "Todos os Departamentos"
            this.activeFilterTags.push({ text: tagText, title: tagTitle })
            
            let uoTags = [...this.uoToFilter]
            this.orderAlphabetically(uoTags)

            // Less than 10 UO = 1 tag for each.
            if (uoTags.length <= 10) {
                for (const uo of uoTags) {
                    tagText = tagTitle = uo.name
                    this.activeFilterTags.push({ text: tagText, title: tagTitle })
                }
            
            // More than 10 UO = 1 tag for All UO.
            } else {
                tagText = `${uoTags.length} Unidades`
                tagTitle = ""

                for (const uo of uoTags) {
                    tagTitle += `${uo.name}\n`
                }

                this.activeFilterTags.push({ text: tagText, title: tagTitle })
            }
            
            if (this.selectedCampaign != null) {
                tagText = tagTitle = `${this.selectedCampaign.name} - Início em ${this.displayDateFormat(this.selectedCampaign.startDate)}`
            } else {
                tagText = tagTitle = "Todas as Campanhas"
            }
            this.activeFilterTags.push({ text: tagText, title: tagTitle })

            tagText = tagTitle = `${this.selectedStartDate} até ${this.selectedEndDate}`
            this.activeFilterTags.push({ text: tagText, title: tagTitle })
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.dashboard-filter {
    position: relative;
	margin-bottom: responsive-height(25px);
}

.filter-selection {
    width: 100%;
}

.filter-buttons {
    width: 100%;
    justify-content: center;

    .filter-btn { width: 100px; }
    .reset-btn { width: 100px; }
}

.active-filters-display {
    margin-bottom: responsive-height(18px);
}

/* Notification Animation */
.notification-enter, .notification-leave-to {
    opacity: 0; 
}

.notification-move, .notification-enter-active {
    transition: all .3s;
}

.notification-leave-active {
    position: absolute;
    width: calc(100% - 4.6vh);
    transition: all .3s;
}
</style>