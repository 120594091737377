<template>
    <div class="panel doses-distributed">

        <h4 class="panel-title">Distribuição de Doses</h4>

        <div class="content-wrapper">
            <div class="panel-content">
                <h2 class="panel-value">{{ dosesAvailable | addDots }}</h2>
                <h5 class="panel-label">Disponíveis<br>para Adesão</h5>
            </div>

            <div class="division-line"></div>
            
            <div class="panel-content">
                <h2 class="panel-value">{{ dosesDistributed | addDots }}</h2>
                <h5 class="panel-label">Total<br>Distribuídos</h5>
            </div>
        </div>

    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"

export default {
    mixins: [ dashUtilsMixin ],
    
    props: {
        panelData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            animatedDosesAvailable: 0,
            animatedDosesDistributed: 0
        }
    },

    computed: {
        dosesAvailable() {
            return this.animatedDosesAvailable.toFixed(0)
        },
        dosesDistributed() {
            return this.animatedDosesDistributed.toFixed(0)
        }
    },

    mounted() {
        this.animateNumber('animatedDosesAvailable', this.panelData.disponiveis)
        this.animateNumber('animatedDosesDistributed', this.panelData.distribuidas)
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

.doses-distributed {
    .content-wrapper {
        display: block;
        margin-top: responsive-height(36px);
    }
    
    .panel-content {
        display: flex;
        margin-top: 0;
        flex-direction: row;
    }

    .panel-value {
        width: 50%;
        text-align: right;
    }
    
    .panel-label {
        margin-left: responsive-width(30px);
        width: 50%;
        text-align: left;
    }
    
    .division-line {
        height: 1px;
        width: 80%;
        margin: responsive-height(18px) auto;
        background-color: #EBE9F1;
    }
}

// MOBILE
@media only screen and (max-width: 780px) {
    .doses-distributed .content-wrapper {
        margin-top: 20px;
    }
}
</style>