<template>
    <div class="panel company-adherence">

        <h4 class="panel-title">Adesões por Empresas</h4>
        
        <div class="content-wrapper">
            <div class="panel-content">
                <img class="panel-icon" :src="companyAdhesionImg" alt="ícone empresas com adesão">
                <h2 class="panel-value">{{ companyAdhesion | addDots }}</h2>
                <h5 class="panel-label">Empresas<br>com Adesão</h5>
            </div>

            <div class="division-line"></div>

            <div class="panel-content">
                <img class="panel-icon" :src="companyRegisteredImg" alt="ícone empresas cadastradas">
                <h2 class="panel-value">{{ companyRegistered | addDots }}</h2>
                <h5 class="panel-label">Empresas<br>Cadastradas</h5>
            </div>
        </div>
        <p class="text-adhesion">Vinculadas com pelo menos uma unidade operacional</p>
    </div>
</template>

<script>
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin";

export default {
    mixins: [dashUtilsMixin],

    props: {
        panelData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            companyAdhesionImg: require(`@/assets/custom-icons/pages/dashboard/company-green-icon.png`),
            companyRegisteredImg: require(`@/assets/custom-icons/pages/dashboard/company-yellow-icon.png`),
            animatedCompanyAdhesion: 0,
            animatedCompanyRegistered: 0
        };
    },

    computed: {
        companyAdhesion() {
            return this.animatedCompanyAdhesion.toFixed(0)
        },
        companyRegistered() {
            return this.animatedCompanyRegistered.toFixed(0)
        }
    },

    mounted() {
        this.animateNumber('animatedCompanyAdhesion', this.panelData.empresas_adesao)
        this.animateNumber('animatedCompanyRegistered', this.panelData.empresas_cadastradas)
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";

.division-line {
    margin-top: responsive-height(36px);
    height: responsive-height(134px);
    width: 1px;
    background-color: #EBE9F1;
}

.text-adhesion{
    text-align: center;
    margin-top: responsive-height(10px);
    font-size: responsive-height(10px);


}
</style>